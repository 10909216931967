<template>
  <div class="h-96 w-80 bg-white rounded-md relative hover:border-primary border-2 border-transparent transition">
    <div id="product_name" class="absolute" style="z-index:100;">
      <p class="pt-4 pl-8 font-bold text-black">{{ i18n.locale.value == "en" ? translation : product.name }}</p>
    </div>
    <div id="product_image" class="mt-2 flex justify-center">
      <img :src="product.thumbnail_url" alt="" class="h-80"/>
    </div>
    <div class="flex justify-center absolute bottom-5 w-full">
      <div
        id="configure_button"
        class="
        group
          h-12
          w-full
          flex
          justify-center
          pt-2.5
          mr-5
          ml-5
          border-2 border-primary
          rounded-md
          cursor-pointer
          hover:bg-primary
          transition
        "
         @click="chooseProduct(); "
      >
        <p class="font-bold text-primary group-hover:text-white">{{ $t("configureNow") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
// import { useStore } from "vuex";
// import { PRODUCTS_TYPES, STORE_TYPES } from "../../../store/types";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "ProductCard",
  props: ["name",'product'],
setup(props) {
    // const store = useStore();
    /**
     *  State
     */
    const state = reactive({
    });
    const i18n = useI18n()

    const router = useRouter()
    /**
     *  Functions
     */
    const translation = computed(()=>{
      let translatedName = ""
      props.product.translations.forEach(translation => {
        if(translation.language_key == i18n.locale.value)
        {
          translatedName = translation.translation
        }
      });
      return translatedName
    })
    async function chooseProduct()
    {
      
      router.push({name: 'Configurator', params: {ID:props.product.id}})
    }
    return {
      state,
      chooseProduct,
      i18n,
      translation
    };
  },
});
</script>
